import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1403642a&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TIcon: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Icon.vue').default,THero: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Hero.vue').default,TContainer: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Container.vue').default,LHorizontalScrollBar: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/layouts/HorizontalScrollBar.vue').default,LSummitDifference: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/layouts/SummitDifference.vue').default,LImageAndText: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/layouts/ImageAndText.vue').default,LGradientSection: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/layouts/GradientSection.vue').default,LTrustPilotReviews: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/layouts/TrustPilotReviews.vue').default,NavSearchNav: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/nav/SearchNav.vue').default,TVideoHero: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/VideoHero.vue').default})
